import 'react-app-polyfill/ie11' // For IE 11 support
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { AuthProvider } from './lib/auth'

import 'bootstrap/dist/css/bootstrap.css'
import './index.css'
import App from './App'
import apolloClient from './apolloClient'
import * as serviceWorker from './serviceWorker'

import { BlockAppAccess, BLOCK_APP_ACCESS } from './BlockAppAccess'

const AppWrapper = () => (
  <ApolloProvider client={apolloClient}>
    <Router basename={process.env.PUBLIC_URL}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  </ApolloProvider>
)

const RenderView = () => BLOCK_APP_ACCESS ? <BlockAppAccess /> : <AppWrapper />

ReactDOM.render(RenderView(), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
