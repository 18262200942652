import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

export const NavigationBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const SideNavLink = styled(NavLink)`
  font-family: ${Theme.fonts.Montserrat};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  position: relative;

  &.active {
    font-weight: 700;
  }
  
  &.active::before {
    content: '';
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${Theme.colors.gold};
    bottom: -10px;
    border-bottom: 4px solid;
  }

  :hover {
    text-decoration: none;
    color: ${Theme.colors.gold};
  }
`

export const LinkStyled = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;

  width: 100%;
  flex-grow: 45;
  position: relative;

  &.active::before {
    content: '';
    position: absolute;
    color: ${Theme.colors.gold};
    vertical-align: middle;
    width: 100%;
    bottom: -31px;
    border-bottom: 4px solid;
  }

  :hover,
  &.active {
    text-decoration: none;
    color: #fff;
  }
`
