import React from 'react'
import styled from 'styled-components'
import { Footer, footerOptions, optionsFor } from '@pga/pga-component-library'
import { withAuthProvider } from '../../lib/auth'

export const FooterContainer = styled.div`
  text-align: left;
`

export const FooterWrapper = ({ isLoggedIn, me }) =>
  <FooterContainer>
    <Footer
      sections={optionsFor(me)}
      copyright={footerOptions.copyrightMessage}
    />
  </FooterContainer>

export default withAuthProvider(FooterWrapper)
