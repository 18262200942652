import React from 'react'
import { Error, Button } from '@pga/pga-component-library'

export const BLOCK_APP_ACCESS = false

export const BlockAppAccessMessage = () => <div>
  <h3>PGA.org is undergoing maintenance.</h3>
  <p className='mt-3'>
    We are sorry for the inconvenience. We will have everything back online as quickly as we can. You can still access Jobs, Resources and the Directory.
  </p>
  <Button href='https://jobs.pga.org' target='_blank' rel='noopener noreferrer' className='m-2'>Jobs</Button>
  <Button href='https://resources.pga.org' target='_blank' rel='noopener noreferrer' className='m-2'>Resources</Button>
  <Button href='https://directory.pga.org' target='_blank' rel='noopener noreferrer' className='m-2'>Directory</Button>
  <Button href='https://news.pga.org' target='_blank' rel='noopener noreferrer' className='m-2'>News</Button>
</div>
export const BlockAppAccess = () => (
  <Error Message={BlockAppAccessMessage} />
)
