import React from 'react'
import { MyAccountButton, MemberDropdown } from '@pga/pga-component-library'

export const MemberDrop = ({ me, loading, options = [], meEmailMigrationData, onToggleDoNotShowAgain }) => (
  <MemberDropdown me={me} loading={loading} options={options} meEmailMigrationData={meEmailMigrationData} onToggleDoNotShowAgain={onToggleDoNotShowAgain} />
)

export default ({ isLoggedIn, me, options, meEmailMigrationData, onToggleDoNotShowAgain }) => (
  isLoggedIn
    ? <MemberDrop {...{ me, options, meEmailMigrationData, onToggleDoNotShowAgain }} />
    : <MyAccountButton />
)
