import React from 'react'
import { isEmpty, compose } from 'ramda'
import { withAuthProvider } from '../../lib/auth'
import { Header, Nav, IconsBox, MobileNavigation, Theme, buildNavTree, LoadingComponent, IframeRolex } from '@pga/pga-component-library'
import MediaQuery from 'react-responsive'
import logo from '../../assets/images/logo.svg'
import { getNavOptions, dropdownLinks } from './options'
import AuthHeader from './AuthHeader'
import { NavigationBox, LinkStyled, SideNavLink } from './styled'
import { withEmailMigrationData, withSetEmailMigrationMutation } from './query'

export const DesktopHeader = ({ isLoggedIn, me, navOptions, authReady, meEmailMigrationData, onToggleDoNotShowAgain }) => (
  <NavigationBox>
    <Nav options={navOptions} comp={LinkStyled} />
    <div className='d-flex align-items-center'>
      <IframeRolex />
      <IconsBox {...{ isLoggedIn, meEmailMigrationData, onToggleDoNotShowAgain, me }} />
    </div>
    <AuthHeader {...{ isLoggedIn, me, authReady, options: dropdownLinks, meEmailMigrationData, onToggleDoNotShowAgain }} />
  </NavigationBox>
)

export const MobileHeader = ({ isLoggedIn, me, mobileOptions, subOptions, meEmailMigrationData, onToggleDoNotShowAgain }) => (
  <MobileNavigation
    {...{
      options: buildNavTree(mobileOptions, subOptions),
      b: -6,
      l: 18,
      linkComponent: SideNavLink,
      vertical: true,
      me: isEmpty(me) ? null : me,
      isLoggedIn,
      meEmailMigrationData,
      onToggleDoNotShowAgain
    }}
  />
)

export const HeaderWrapper = (props) => {
  const { isLoggedIn, authReady, emailMigrationData, emailMigrationMutation } = props
  const { navOptions, mobileOptions, subOptions } = getNavOptions(isLoggedIn)
  const logoLink = isLoggedIn ? 'https://account.pga.org' : 'https://beta.pga.org'

  const { loading, meEmailMigrationData: migrationData } = emailMigrationData || {}
  const meEmailMigrationData = migrationData || {}
  const { id, doNotShowAgain } = meEmailMigrationData

  const onToggleDoNotShowAgain = () => {
    emailMigrationMutation({ variables: { id, input: { doNotShowAgain: !doNotShowAgain } } })
  }

  if (!authReady || loading) {
    return <LoadingComponent />
  }

  return (
    <Header {...{ logo, bgColor: Theme.colors.primary, logoLink }}>
      <MediaQuery minWidth={992}>
        {(matches) => matches
          ? <DesktopHeader {...props} {...{ navOptions }} {...{ meEmailMigrationData, onToggleDoNotShowAgain }} />
          : <MobileHeader {...props} {...{ mobileOptions, subOptions }} {...{ meEmailMigrationData, onToggleDoNotShowAgain }} />
        }
      </MediaQuery>
    </Header>
  )
}

export default compose(
  withEmailMigrationData,
  withSetEmailMigrationMutation,
  withAuthProvider
)(HeaderWrapper)
