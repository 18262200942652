export const pgaTld = window.location.hostname.replace(/^[^.]+\.(sandbox)?pga.org/, '$1pga.org') || 'pga.org'

const options = {
  public: {
    desktop: [
      {
        id: 1,
        to: `https://www.${pgaTld}`,
        label: 'Home',
        external: true
      },
      {
        id: 2,
        to: `https://jobs.${pgaTld}`,
        label: 'Jobs',
        external: true
      },
      {
        id: 3,
        to: `https://directory.${pgaTld}`,
        label: 'Directory',
        external: true
      },
      {
        id: 4,
        to: `https://news.${pgaTld}`,
        label: 'News',
        external: true
      },
      {
        id: 5,
        to: '/',
        label: 'Events',
        root: true
      }
    ],
    mobile: [
      {
        id: 7,
        to: 'https://webmail.pgalinks.com/index.php',
        label: 'My Email',
        external: true,
        target: '_blank'
      },
      {
        id: 8,
        to: 'http://apps.pga.org/professionals/apps/membergolfshop/index.cfm?SMSESSION=NO',
        label: 'Shop',
        external: true,
        target: '_blank'
      },
      {
        id: 9,
        to: `${process.env.REACT_APP_AUTH_FLOW_BASE_URI}/login?return_to=${window.location.href}`,
        label: 'Login',
        external: true
      },
      {
        id: 10,
        to: `https://account.${pgaTld}/signup`,
        label: 'Create Account',
        external: true
      }
    ],
    subNavigation: []
  },
  authenticated: {
    desktop: [
      {
        id: 1,
        to: `https://account.${pgaTld}`,
        label: 'My Account',
        external: true
      },
      {
        id: 2,
        to: `https://jobs.${pgaTld}`,
        label: 'Jobs',
        external: true
      },
      {
        id: 3,
        to: `https://directory.${pgaTld}`,
        label: 'Directory',
        external: true
      },
      {
        id: 4,
        to: `https://news.${pgaTld}`,
        label: 'News',
        external: true
      },
      {
        id: 5,
        to: '/',
        label: 'Events',
        root: true
      },
      {
        id: 6,
        to: `https://resources.${pgaTld}`,
        label: 'Resources',
        external: true
      },
      {
        id: 7,
        to: `https://resources.${pgaTld}/my-membership/pga-frisco`,
        label: 'PGA Frisco',
        external: true
      }
    ],
    mobile: [
      {
        id: 10,
        to: 'https://webmail.pgalinks.com/index.php',
        label: 'My Email',
        external: true,
        target: '_blank'
      },
      {
        id: 11,
        to: 'http://apps.pga.org/professionals/apps/membergolfshop/index.cfm?SMSESSION=NO',
        label: 'Shop',
        external: true,
        target: '_blank'
      },
      {
        id: 12,
        to: `${process.env.REACT_APP_AUTH_FLOW_BASE_URI}/logout`,
        label: 'Logout',
        external: true
      }
    ],
    subNavigation: []
  }
}

export const getNavOptions = isLoggedIn => {
  const navOptions = isLoggedIn ? options.authenticated.desktop : options.public.desktop
  const mobileOptions = isLoggedIn ? navOptions.concat(options.authenticated.mobile) : navOptions.concat(options.public.mobile)
  const subOptions = isLoggedIn ? options.authenticated.subNavigation : options.public.subNavigation
  return {
    navOptions,
    mobileOptions,
    subOptions
  }
}

export const dropdownLinks = [
  {
    to: 'https://webmail.pgalinks.com/index.php',
    label: 'My Email',
    external: true,
    target: '_blank'
  }
]

export default options
