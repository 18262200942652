import React from 'react'
import styled from 'styled-components'
import Layout from './Layout'
import Routes from './Routes'
import IntercomWithAuth from './components/Intercom'
import { ImpersonationOverlay } from './lib/auth'

const MainBox = styled.main`
  height: 80vh;
`

const App = () => {
  return (
    <MainBox>
      <ImpersonationOverlay />
      <Layout>
        <Routes />
        <IntercomWithAuth />
      </Layout>
    </MainBox>
  )
}

export default App
