import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import { NotFound, withPageMetadata, metadata, LoadingComponent } from '@pga/pga-component-library'
import { RedirectToLogin, RedirectToLogout } from './lib/auth'

const SearchPageAsync = Loadable({ loader: () => import('./SearchPage'), loading: LoadingComponent })
const FeaturedPageAsync = Loadable({ loader: () => import('./FeaturedPage'), loading: LoadingComponent })
const DetailPageAsync = Loadable({ loader: () => import('./DetailPage'), loading: LoadingComponent })

export const routes = [
  {
    path: '/',
    exact: true,
    component: withPageMetadata(metadata('Featured'))(FeaturedPageAsync)
  },
  {
    path: '/search',
    exact: true,
    component: withPageMetadata(metadata('Search'))(SearchPageAsync)
  },
  {
    path: '/event/:slug',
    exact: true,
    component: withPageMetadata(metadata('Event Details'))(DetailPageAsync)
  },
  {
    path: '/login',
    exact: true,
    component: (props) => <RedirectToLogin returnTo={`${window.location.origin}/`} {...props} />
  },
  {
    path: '/logout',
    exact: true,
    component: (props) => <RedirectToLogout {...props} />
  },
  {
    component: withPageMetadata(metadata('Not Found'))(NotFound)
  }
]

const renderRoute = (route, idx) => <Route key={idx} {...route} />

const Routes = () => (
  <Switch>
    {routes.map(renderRoute)}
  </Switch>
)

export default Routes
